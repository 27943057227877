<template>
  <div class="user">
    <!-- 面包屑导航 -->
    <el-card>
      <div class="contaioner">
      <div class="box-left">
          <div class="item title normal"><h2>订单信息</h2></div>
          <div  class="item shoppng normal" v-for="item in tableData">
              <div class="shop-name">桌号：{{ item.desk }}</div>
              <div class="shop-sku" style="font-size: 16px;">订单号：{{item.order}}</div>
          </div>
      </div>
      <div class="box-right">
          <div class="item title normal"><h2>商品信息</h2></div>
          <template >
          <div  class="item-item"  v-for="(item,index) in tableData">
              <div class="shop-info" :class="{gray:item1.dining_colour_status==0,green:item1.dining_colour_status==1,red:item1.dining_colour_status==2,redPlus:item1.dining_colour_status==3}"   @click="onShopping(item1.sub_id)" v-for="(item1,index1) in item.sub_order_list">
                  <div class="shop-name">商品名称：{{item1.c_name}}</div>
                  <div class="shop-sku">规格：{{ item1.spec_key_name }}</div>
                  <div class="shop-date1">出餐时间：{{ item1.meal_time }}</div>
                  <!-- <div class="shop-date">下单时间：{{item1.create_time}}</div> -->
              </div>
          </div>
      </template>
      </div>
  </div>
      <el-pagination
        @current-change="pageChange"
        @size-change="sizeChange"
        :page-sizes="[7, 15, 20]"
        layout="total, sizes, prev, pager, next"
        :page-size="this.getInfo.page_num"
        background
        :total="exp.total"
        style="margin-top: 20px;"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {PostOrderList,PostSetDiningOut} from '@/api/orderList'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
        var validateItem = (rule, value, callback) => {
        if (this.form.item === '') {
          callback(new Error('请输入人数'));
        } else {
          callback();
        }
      };
    //这里存放数据
    return {
         time:null,
         rules: {
          item: [
            { validator: validateItem, trigger: 'blur' }
          ],         
        },
      //总条数
      exp: {
        count: Number,
        total: 0,
      },
      //当前页码
      getInfo: {
        //搜索框
        info: "",
        page_code: 1,
        page_num: 15,
      },
      form:{
        item:''
      },
      props:{
        children:'sub',
        value:'id',
        label:'name'
      },
      //表格数据
         tableData: [],
            token: {
        token: window.sessionStorage.getItem("token"),
      },
      formLabelWidth: "100px",
       imgParam: {
        path: "contract/template",
      },
        fileList: [],
        //控制弹窗--新增修改
      dialogFormVisible: false,
      addFlag:false,
      lookFalg:false,
      updataFalg:false,
    };
  },
  //监听属性 类似于data概念
  computed: {
        imgUrl() {
      return this.$store.state.imgUrl;
    },
  },
  //监控data中的数据变化
  watch: {
           dialogFormVisible(newVal, oldVal) {
      if (!newVal) {
        this.form = this.$options.data().form;
        this.updataFalg=false;
        this.lookFalg=false;
        this.addFlag=false;
      }
    },
  },
  //方法集合
  methods: {
      //点击商品
      onShopping(sub_id){
       this.PostSetDiningOut({
          sub_id:sub_id
       })
      },
    //修改每页数量
    sizeChange(value) {
      this.getInfo.page_num = value;
      var data = {
        page: this.getInfo.page_code, 
        list_rows: this.getInfo.page_num,
      };
      console.log(data);
      this.tableData = [];
      this.PostOrderList(data);
    },
    //分页切换
    pageChange(value) {
      this.getInfo.page_code = value;
      var data = {
        page: this.getInfo.page_code,
        list_rows: this.getInfo.page_num,
      };
      console.log(data);
      this.tableData = [];
      this.PostOrderList(data);
    },
          exceed() {
      this.$message({
        message: "数量超出",
        type: "warning",
      });
    },
    //导入图片事件
    imgSuccess(response, file, fileList) {
        // console.log(response)
      this.form.url = response.data.url;
    },

    //移除图片的事件
    imgRemove(file, fileList) {
      this.form.url = "";
    },
    /**-----------------网络请求生命周期函数--------------------- */
    //出餐列表
    async PostOrderList(data){
        let res=await PostOrderList(data);
        //console.log(res)
        this.tableData = res.data.data.data;
      this.exp.total = res.data.data.total;
    },
    //出餐操作
    async PostSetDiningOut(data){
        let res=await PostSetDiningOut(data);
        if(res.data.code==1){
          this.$message({
        message: "操作成功",
        type: "success",
      });
      this.PostOrderList(data);
        }else{
          this.$message("操作失败");
        }
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    var data = {
      page: this.getInfo.page_code,
      list_rows: this.getInfo.page_num,
    };
    this.PostOrderList(data);
    this.time=setInterval(()=>{
      this.PostOrderList(data);
    },60000)
  },

  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {
      clearInterval(this.time);
  }, //生命周期 - 销毁完成
};
</script>

<style scoped>
.page {
  margin-top: 20px;
}
.contaioner{
  display: flex;
}
.box-left{
  width: 300px;
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  margin-right: -1px;
}
.box-right{
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid black;
}
.item{
  display: flex;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
}
.item-item{
  flex: 1;
  display: flex;
  height: 160px;
  cursor:pointer;
}
.shop-info{
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  font-size: 1.5em;
  /* background-color: pink; */
}
.title{
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.shoppng{
  height:160px;
  font-size: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/* .shoppng>div{
  width: 100%;
} */
.shop-sku{

  text-align: left;
  margin: 10px 0;
}
.shop-date1{
  margin-bottom: 10px;
}
.green{
  background-color: rgba(0,128,0,.5);
  color: white;
}

.gray{
  background-color: rgba(128,128,128,.5);
  color: white;
}
.red{
  background-color: rgba(255,0,0,.5);
  color: white;
}
.redPlus{
  background-color: rgba(255,0,0,.7);
  color: white;
}
.normal{
  /* background-color: #72492b;
  color: white; */
}
</style>
